<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="阿里云配置" name="1">
                    <el-form class="form-box" ref="aliForm" v-bind:rules="rules" v-bind:model="aliForm" label-width="100px">
                        <el-form-item label="bucket" prop="bucket">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.bucket" placeholder="请输入bucket"></el-input>
                        </el-form-item>
                        <el-form-item label="internal" prop="internal">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.internal" placeholder="请输入internal"></el-input>
                        </el-form-item>
                        <el-form-item label="key" prop="key">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.key" placeholder="请输入key"></el-input>
                        </el-form-item>
                        <el-form-item label="ossurl" prop="ossurl">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.ossurl" placeholder="请输入ossurl"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="url" prop="url">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.url" placeholder="请输入url"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveAliForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="钉钉配置" name="2">
                    <el-form class="form-box" ref="dingForm" v-bind:rules="rules" v-bind:model="dingForm" label-width="100px">
                        <el-form-item label="keyword" prop="keyword">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="dingForm.keyword" placeholder="请输入keyword"></el-input>
                        </el-form-item>
                        <el-form-item label="persion" prop="persion">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="dingForm.persion" placeholder="请输入persion"></el-input>
                        </el-form-item>
                        <el-form-item label="token" prop="token">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="dingForm.token" placeholder="请输入token"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="savedingForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane label="支付宝app配置" name="4">
                    <el-form class="form-box" ref="zfbForm" v-bind:rules="rules" v-bind:model="zfbForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveZfubForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="支付宝小程序配置" name="5">
                    <el-form class="form-box" ref="zfbAppletForm" v-bind:rules="rules" v-bind:model="zfbAppletForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbAppletForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbAppletForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbAppletForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="zfbAppletForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveZfubAppletForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="QQh5配置" name="6">
                    <el-form class="form-box" ref="qqH5Form" v-bind:rules="rules" v-bind:model="qqH5Form" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qqH5Form.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qqH5Form.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qqH5Form.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qqH5Form.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveQqH5Form">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="头条配置(支付)" name="7" >
                    <el-form class="form-box" ref="toutiaoH5Form" v-bind:rules="rules" v-bind:model="toutiaoH5Form" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="merchant_no" prop="merchant_no">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.merchant_no" placeholder="请输入merchant_no"></el-input>
                        </el-form-item>
                        <el-form-item label="payaddress(废弃)" prop="payaddress">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.payaddress" placeholder="请输入payaddress"></el-input>
                        </el-form-item>
                        <el-form-item label="payappid(废弃)" prop="payappid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.payappid" placeholder="请输入payappid"></el-input>
                        </el-form-item>
                        <el-form-item label="paysecret(废弃)" prop="paysecret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.paysecret" placeholder="请输入paysecret"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="token(支付)" prop="token">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.token" placeholder="请输入token"></el-input>
                        </el-form-item>
                        <el-form-item label="salt(支付)" prop="salt">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="toutiaoH5Form.salt" placeholder="请输入salt"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveToutiaoH5Form">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="个推配置" name="8">
                    <el-form class="form-box" ref="AppForm" v-bind:rules="rules" v-bind:model="AppForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AppForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AppForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="appsecret" prop="appsecret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AppForm.appsecret" placeholder="请输入appsecret"></el-input>
                        </el-form-item>
                        <el-form-item label="masterSecret" prop="masterSecret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AppForm.masterSecret" placeholder="请输入masterSecret"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveAppForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="快递配置" name="9">
                    <el-form class="form-box" ref="AzExpressForm" v-bind:rules="rules" v-bind:model="AzExpressForm" label-width="110px">
                        <el-form-item label="logisticssalt" prop="logisticssalt">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AzExpressForm.logisticssalt" placeholder="请输入logisticssalt"></el-input>
                        </el-form-item>
                        <el-form-item label="customer" prop="customer">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AzExpressForm.customer" placeholder="请输入customer"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="AzExpressForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveAzExpressForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="仁联配置" name="10">
                    <el-form class="form-box" ref="RenlianFrom" v-bind:rules="rules" v-bind:model="RenlianFrom" label-width="110px">
                        <el-form-item label="bkkey" prop="bkkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="RenlianFrom.bkkey" placeholder="请输入bkkey"></el-input>
                        </el-form-item>
                        <el-form-item label="ckkey" prop="ckkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="RenlianFrom.ckkey" placeholder="请输入ckkey"></el-input>
                        </el-form-item>
                        <el-form-item label="merchon" prop="merchon">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="RenlianFrom.merchon" placeholder="请输入merchon"></el-input>
                        </el-form-item>
                        <el-form-item label="url" prop="url">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="RenlianFrom.url" placeholder="请输入url"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveRenlianFrom">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane label="建行龙卡分期" name="11">
                    <el-form class="form-box" ref="CcbLongCardInstallFrom" v-bind:rules="rules" v-bind:model="CcbLongCardInstallFrom" label-width="110px">
                        <el-form-item label="bank_url" prop="bank_url">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="CcbLongCardInstallFrom.bank_url" placeholder="请输入bank_url"></el-input>
                        </el-form-item>
                        <el-form-item label="merchant_id" prop="merchant_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="CcbLongCardInstallFrom.merchant_id" placeholder="请输入merchant_id"></el-input>
                        </el-form-item>
                        <el-form-item label="pos_id" prop="pos_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="CcbLongCardInstallFrom.pos_id" placeholder="请输入pos_id"></el-input>
                        </el-form-item>
                        <el-form-item label="branch_id" prop="branch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="CcbLongCardInstallFrom.branch_id" placeholder="请输入branch_id"></el-input>
                        </el-form-item>
                        <el-form-item label="pub_key" prop="pub_key">
                            <el-input class="form-input" type="textarea" auto-complete="off" v-model="CcbLongCardInstallFrom.pub_key" placeholder="请输入pub_key"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveCcbLongCardInstallFrom">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="资和信ott配置" name="12">
                    <el-form class="form-box" ref="RenlianFrom" v-bind:rules="rules" v-bind:model="OttConfigFrom" label-width="110px">
                        <el-form-item :label="index" :prop="index" v-for="(item,index) in OttConfigFrom ">
                            <el-input class="form-input" type="textarea" auto-complete="off" v-model="OttConfigFrom.index" :placeholder="'请输入'+index"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveRenlianFrom">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="银联云闪付" name="14">
                    <el-form class="form-box" ref="ChinaumsFrom" v-bind:rules="rules" v-bind:model="ChinaumsFrom" label-width="180px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ChinaumsFrom.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ChinaumsFrom.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号mid" prop="mid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ChinaumsFrom.mid" placeholder="请输入商户号mi"></el-input>
                        </el-form-item>
                        <el-form-item label="终端号tid" prop="tid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ChinaumsFrom.tid" placeholder="请输入终端号tid"></el-input>
                        </el-form-item>
                        <el-form-item label="服务地址baseUrl" prop="baseUrl">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="ChinaumsFrom.baseUrl" placeholder="请输入baseUrl"></el-input>
                        </el-form-item>
                        <el-divider></el-divider>
                        <el-row>
                            <el-col v-for="(item,index) in ChinaumsFrom.channels" :key="index">
                                <el-form-item :label="item.name" prop="enable">
                                    <el-avatar shape="square" size="128px" :src="item.icon"></el-avatar>
                                    <br>
                                    <el-switch
                                        v-model="item.enable"
                                        active-text="启用"
                                        inactive-text="禁用"
                                        :active-value="true"
                                        :inactive-value="false">
                                    </el-switch>
                                </el-form-item>
                                <el-form-item label="业务地址url" prop="pay_url">
                                    <el-input class="form-input" type="text" auto-complete="off" v-model="item.pay_url" placeholder="请输入业务地址url"></el-input>
                                </el-form-item>
                                <el-divider></el-divider>
                            </el-col>
                        </el-row>
                        <el-form-item label>
                            <el-button type="primary" @click="saveChinaumsFrom">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: '',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            activeName: '1',
            menuList: ['设置', '系统配置'],
            aliForm: {},
            dingForm: {},
            versionForm: {},
            header: {},
            AppForm: {},
            zfbForm: {},
            qqH5Form: {},
            toutiaoH5Form: {},
            zfbAppletForm: {},
            AzExpressForm: {},
            RenlianFrom: {},
            CcbLongCardInstallFrom:{},
            OttConfigFrom: {
                server_url:"",
                merchant_id:"",
                ott_pri_rsa_key:"",
                ott_pub_rsa_key:"",
                ott_aes_key:"",
                local_pri_rsa_key:"",
                local_pub_rsa_key:"",
                local_aes_key:"",
            },
            ChinaumsFrom:{
                appid: "",
                appkey: "",
                mid: "",
                tid: "",
                baseUrl: "",
                channels: {
                    wechat: {
                        enable: false,
                        icon: "",
                        pay_url: "",
                        name: ""
                    },
                    alipay: {
                        enable: false,
                        icon: "",
                        pay_url: "",
                        name: ""
                    },
                    ums: {
                        enable: false,
                        icon: "",
                        pay_url: "",
                        name: ""
                    }
                }
            },
            rules: {
                bucket: [
                    {
                        required: true,
                        message: 'bucket不能为空',
                        trigger: 'blur'
                    }
                ],
                appkey: [
                    {
                        required: true,
                        message: 'appkey 不能为空',
                        trigger: 'blur'
                    }
                ],
                mch_id: [
                    {
                        required: true,
                        message: 'mch_id不能为空',
                        trigger: 'blur'
                    }
                ],
                internal: [
                    {
                        required: true,
                        message: 'internal不能为空',
                        trigger: 'blur'
                    }
                ],
                key: [
                    {
                        required: true,
                        message: 'key不能为空',
                        trigger: 'blur'
                    }
                ],
                ossurl: [
                    {
                        required: true,
                        message: 'ossurl不能为空',
                        trigger: 'blur'
                    }
                ],
                url: [
                    {
                        required: true,
                        message: 'url不能为空',
                        trigger: 'blur'
                    }
                ],
                keyword: [
                    {
                        required: true,
                        message: 'keyword不能为空',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'appsecret不能为空',
                        trigger: 'blur'
                    }
                ],
                masterSecret: [
                    {
                        required: true,
                        message: 'masterSecret不能为空',
                        trigger: 'blur'
                    }
                ],
                persion: [
                    {
                        required: true,
                        message: 'persion不能为空',
                        trigger: 'blur'
                    }
                ],
                uid: [
                    {
                        required: true,
                        message: '审核id不能为空',
                        trigger: 'blur'
                    }
                ],
                token: [
                    {
                        required: true,
                        message: 'token不能为空',
                        trigger: 'blur'
                    }
                ],
                salt: [
                    {
                        required: true,
                        message: 'salt不能为空',
                        trigger: 'blur'
                    }
                ],
                merchant_no: [
                    {
                        required: true,
                        message: 'merchant_no不能为空',
                        trigger: 'blur'
                    }
                ],
                desc: [
                    {
                        required: true,
                        message: 'desc不能为空',
                        trigger: 'blur'
                    }
                ],
                examine: [
                    {
                        required: true,
                        message: '审核版本不能为空',
                        trigger: 'blur'
                    }
                ],
                v: [
                    {
                        required: true,
                        message: 'v不能为空',
                        trigger: 'blur'
                    }
                ],

                maximum: [{
                    required: true,
                    message: '额度不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                minimum: [{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                appid: [
                    {
                        required: true,
                        message: 'appid不能为空',
                        trigger: 'blur'
                    }
                ],
                // uri: [
                //     { validator: deal.checkFile, trigger: ['blur', 'change'] }
                // ],
                secret: [
                    {
                        required: true,
                        message: '版本不能为空',
                        trigger: 'blur'
                    }
                ],
                close: [
                    {
                        required: true,
                        message: '充值配置不能为空',
                        trigger: 'blur'
                    }
                ],
                customer: [
                    {
                        required: true,
                        message: 'customer不能为空',
                        trigger: 'blur'
                    }
                ],
                logisticssalt: [
                    {
                        required: true,
                        message: 'logisticssalt不能为空',
                        trigger: 'blur'
                    }
                ],
                bkkey: [
                    {
                        required: true,
                        message: 'bkkey不能为空',
                        trigger: 'blur'
                    }
                ],
                merchon: [
                    {
                        required: true,
                        message: 'merchon不能为空',
                        trigger: 'blur'
                    }
                ],
                ckkey: [
                    {
                        required: true,
                        message: 'ckkey不能为空',
                        trigger: 'blur'
                    }
                ],
                signature: [
                    {
                        required: true,
                        message: 'signature不能为空',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: 'username不能为空',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadAliForm()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                case '1':
                    this.loadAliForm()
                    break
                case '2':
                    this.loaddingForm()
                    break
                case '3':
                    this.loadversionForm()
                    break
                case '4':
                    this.loadZfbForm()
                    break
                case '5':
                    this.loadZfbAppletForm()
                    break
                case '6':
                    this.loadQqH5tForm()
                    break
                case '7':
                    this.loadToutiaoH5Form()
                    break
                case '8':
                    this.loadAppForm()
                    break
                case '9':
                    this.loadAzExpressForm()
                    break
                case '10':
                    this.loadRenlianFrom()
                    break
                case '11':
                    this.loadCcbLongCardInstallFrom()
                    break
                case '14':
                    this.loadChinaumsFrom()
                    break
            }
        },
        // 阿里云配置
        loadAliForm() {
            this.loading = true;
            request.get('/system/aliyun').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.aliForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 钉钉配置
        loaddingForm() {
            this.loading = true;
            request.get('/system/dinghook').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.dingForm = ret.data;
                    let item = ret.data.persion.join()
                    this.$set(this.dingForm, 'persion', item)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },



        //支付宝app
        loadZfbForm() {
            this.loading = true;
            request.get('/system/zfb/app').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.zfbForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //支付宝小程序配置
        loadZfbAppletForm() {
            this.loading = true;
            request.get('/system/zfb/mp').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.zfbAppletForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //qq配置
        loadQqH5tForm() {
            this.loading = true;
            request.get('/system/qq/h5').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.qqH5Form = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //头条配置
        loadToutiaoH5Form() {
            this.loading = true;
            request.get('/system/tt/mp').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.toutiaoH5Form = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //快递配置
        loadAzExpressForm() {
            this.loading = true;
            request.get('/system/express/config/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.AzExpressForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //仁联配置
        loadRenlianFrom() {
            this.loading = true;
            request.get('/system/renlian/config/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.RenlianFrom = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //建行龙卡分期配置
        loadCcbLongCardInstallFrom() {
            this.loading = true;
            request.get('/system/ccblongcardinstall/config/list').then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.CcbLongCardInstallFrom = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //银联云闪付支付配置
        loadChinaumsFrom() {
            this.loading = true;
            request.get('/system/chinaums/h5/list').then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.ChinaumsFrom = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //个推配置
        loadAppForm() {
            this.loading = true;
            request.get('/system/app/push/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.AppForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 阿里云编辑
        saveAliForm() {
            this.loading = true;
            request.post('/system/aliyun/edit', this.aliForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.versionForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadAliForm()
                    this.$refs.aliForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 钉钉编辑
        savedingForm() {
            this.loading = true;
            request.post('/system/dinghook/edit', this.dingForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.dingForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loaddingForm()
                    this.$refs.dingForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },


        // 支付宝编辑
        saveZfubForm() {
            this.loading = true;
            request.post('/system/zfb/app/edit', this.zfbForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.zfbForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadZfbForm()
                    this.$refs.zfbForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 支付宝小程序编辑
        saveZfubAppletForm() {
            this.loading = true;
            request.post('/system/zfb/mp/edit', this.zfbAppletForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.zfbAppletForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadZfbAppletForm()
                    this.$refs.zfbAppletForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // QQ编辑
        saveQqH5Form() {
            this.loading = true;
            request.post('/system/qq/h5/edit', this.qqH5Form).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.qqH5Form = ret.data;
                    this.$message.success('编辑成功');
                    this.loadQqH5tForm()
                    this.$refs.qqH5Form.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        // 头条编辑
        saveToutiaoH5Form() {
            this.loading = true;
            request.post('/system/tt/mp/edit', this.toutiaoH5Form).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.toutiaoH5Form = ret.data;
                    this.$message.success('编辑成功');
                    this.loadToutiaoH5Form()
                    this.$refs.toutiaoH5Form.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        // 个推编辑
        saveAppForm() {
            this.loading = true;
            request.post('/system/app/push/edit', this.AppForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.AppForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadAppForm()
                    this.$refs.AppForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        // 快递编辑
        saveAzExpressForm() {
            this.loading = true;
            request.post('/system/express/config/edit', this.AzExpressForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.AzExpressForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadAzExpressForm()
                    this.$refs.AzExpressForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        // 仁联编辑
        saveRenlianFrom() {
            this.loading = true;
            request.post('/system/renlian/config/edit', this.RenlianFrom).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.RenlianFrom = ret.data;
                    this.$message.success('编辑成功');
                    this.loadRenlianFrom()
                    this.$refs.RenlianFrom.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        // 建行龙卡分期配置编辑
        saveCcbLongCardInstallFrom() {
            this.loading = true;
            request.post('/system/ccblongcardinstall/config/edit', this.CcbLongCardInstallFrom).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.CcbLongCardInstallFrom = ret.data;
                    this.$message.success('编辑成功');
                    this.loadCcbLongCardInstallFrom()
                    this.$refs.CcbLongCardInstallFrom.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        saveChinaumsFrom() {
            this.loading = true;
            request.post('/system/chinaums/h5/edit', {form:JSON.stringify(this.ChinaumsFrom)}).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.RenlianFrom = ret.data;
                    this.$message.success('编辑成功');
                    this.loadRenlianFrom()
                    this.$refs.RenlianFrom.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
    }
}
</script>
<style scoped>
.el-form-item--small.el-form-item {
    margin-top: 30px;
}

.el-input--small {
    margin-left: 10px;
}

.el-button {
    /* margin-left: 210px; */
    margin: 60px 0 80px 0;
}

.containet {
    background: #ffffff;
}

.form-box {
    width: 100%;
}

.form-input >>> .el-input__inner {
    width: 900px;
    border-radius: 4px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
</style>
